<script>
import { format, parseISO } from "date-fns";
import colores from "@/components/colores.json";
const defaultWaitTimeout = 1000;
let contadorColor = 0;
export default {
  defaultWaitTimeout,
  randomColor: (trueRandom = false) => {
    const indice = trueRandom
      ? Math.floor(Math.random() * colores.length)
      : contadorColor;
    contadorColor++;
    if (contadorColor > colores.length) contadorColor = 0;
    // console.log("randomcolor?", trueRandom, indice, colores[indice]);
    return colores[indice];
  },
  errorHandler: function (e) {
    return e; // TODO Para que sirve esto despues?
  },
  moment: (a = new Date(), b = "yyyy-MM-dd") => {
    try {
      if (typeof a == "string") a = parseISO(a);
      if (!isNaN(a)) a = new Date(a);
      return format(a, b);
    } catch (e) {
      return a;
    }
  },
  currency2: (a = 0, b = 2) => parseFloat(a).toFixed(b),
  currency: (a = 0) => {
    const formatter = new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    return formatter.format(a).replace(/\$/g, "");
  },
  esMovil2: () =>
    Math.min(window.screen.width, window.screen.height) < 768 ||
    window.screen.width < window.screen.height ||
    navigator.userAgent.indexOf("Mobi") > -1,
  esMovil: () => {
    const w = window.innerWidth;
    const h = window.innerHeight;
    const tresholdSize = 1000;
    return w < h || (w < tresholdSize && h < tresholdSize);
  },
  esSafari() {
    return (
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1
    );
  },
  permiso: function (persona = {}, str = "") {
    let p = false;
    for (let modulo in persona.permisos || {})
      if (
        persona.permisos &&
        persona.permisos[modulo] &&
        persona.permisos[modulo][str] &&
        persona.permisos[modulo][str] == true
      )
        p = true;
    if (!p && persona.rol == "administrador") p = true;
    return p;
  },
  esImagen(str = "") {
    str = str.toUpperCase();
    const extensiones = ["JPEG", "JPG", "BMP", "PNG", "WEBP"];
    let es = false;
    for (const ext of extensiones) if (str.indexOf(ext) >= 0) es = true;
    return es;
  },
  // Funciones propias de ENCORP
  transformAddress(address = "") {
    try {
      // Convert to lowercase
      address = address.toLowerCase();
      address = address.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      // Remove auxiliary words
      const auxiliaryWords = ["de", "del", "la"];
      const regex = new RegExp(`\\b(${auxiliaryWords.join("|")})\\b`, "g");
      address = address.replace(regex, "");

      // Split the string into words and numbers
      const parts = address.match(/[a-z]+|\d+/gi);

      // Extract the first letter of each word part and join them
      const wordsPart = parts
        .filter((part) => isNaN(part))
        .map((word) => word[0])
        .join("");

      // Extract the number part and any remaining words
      const numberIndex = parts.findIndex((part) => !isNaN(part));
      const numberPart = parts[numberIndex];
      const remainingParts = parts.slice(numberIndex + 1).join("");

      // Combine words, number, and any remaining parts
      return `${wordsPart} ${numberPart}${remainingParts}`;
    } catch (e) {
      console.error("EADRESS", e);
      return address;
    }
  },
};
</script>
