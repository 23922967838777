<script>
import ApiOffline from "./ApiOffline";
import ApiLocalStorage from "./ApiLocalStorage";
import axios from "axios";
//axios.defaults.headers.common["Content-Type"] = "application/json";
import sha1 from "sha1";
import { v4 as uuidv4 } from "uuid";
const prefijo = "encorpadmin";
const modoProduccion = window.location.href.indexOf("encorp.mx") >= 0;
const modoDevLocal = false;
const modoProduccionCalculado = modoProduccion || !modoDevLocal;
const dominioMaestro = modoProduccionCalculado ? "app.encorp.mx" : "localhost";
const puertoMaestro = modoProduccionCalculado ? "8083" : "8082";
const protocolo = modoProduccionCalculado ? "https" : "http";
const cdn = `${protocolo}://${dominioMaestro}:${puertoMaestro}/files`;
const database =
  localStorage.getItem(prefijo + ".encorpModoHistorico") == "1"
    ? "encorphistorico"
    : "encorp";
const appKey = "encorp.app2";
const defaultTimeout = 1500; // Timeout para esperar a Axios a que responda antes de devolver error
let datos = {
  appName: "ENCORP APP",
  appKey,
  servidor: `${protocolo}://${dominioMaestro}:${puertoMaestro}/${database}`,
  servidorDefault: `${protocolo}://${dominioMaestro}:${puertoMaestro}/${database}`,
  servidorDominioEstandar: dominioMaestro,
  cdn,
  defaultUser: "",
  defaultPw: "",
  customTokenSource: "",
  localStorageDefaultKey: prefijo + ".yo",
  modoProduccion,
  modoProduccionCalculado,
  modoOffline: false,
  localStoragePrefijo: prefijo,
};
console.log("SS", datos.servidor);
let metodos = {
  wait: function (time = defaultTimeout) {
    return new Promise((resolve) => {
      setTimeout(() => {
        console.log("wait " + time);
        resolve(true);
      }, time);
    });
  },
  // Funciones globales
  ping: async (str = "") => {
    str = str || datos.servidor;
    return axios.get(str, {
      headers: {
        "wst-appkey": appKey,
        token: metodos.token(),
      },
    });
  },
  login: async function (
    user = "",
    pass = "",
    dondeGuardarIdentidad,
    noReintentar = false
  ) {
    dondeGuardarIdentidad =
      dondeGuardarIdentidad || datos.localStorageDefaultKey;
    //*/
    metodos.saveLocal("forzarModoOffline", false); // De otro modo, no se guarda la empresa ni se sincronizan los catálogos
    //
    console.log("LOGIN", user);
    let passOriginal = pass || "";
    if (pass.length != 40) pass = sha1(pass || "");
    let resultado = null;
    let error = null;
    let jwtable = null;
    console.info("Login", user, pass);
    try {
      console.log("Intentando auth directa...", datos.servidor);
      let rJWT = await axios.post(
        datos.servidor + "/login",
        {
          email: user,
          password: pass,
        },
        {
          headers: {
            "wst-appkey": appKey,
          },
        }
      );
      if (rJWT && rJWT.data && rJWT.data._id && rJWT.data.jwt) {
        metodos.saveLocal("yo", rJWT.data);
        /*if (
          rJWT.data.servidor &&
          rJWT.data.servidor != "" &&
          rJWT.data.servidor.startsWith("http")
        ) {
          metodos.saveLocal("servidor", rJWT.data.servidor);
          datos.servidor = rJWT.data.servidor;
          console.info("Encontrado servidor especificado", datos.servidor);
        } //*/
        resultado = rJWT.data;
        console.info("AUTH+JWT", resultado.email, resultado.jwt.length);
        const empresas = await metodos.get("empresa");
        if (empresas && empresas[0]) {
          const empresa = empresas[0];
          console.log("apiwst login empresa?", empresa.nombre || empresa);
          metodos.saveLocal("empresadb", empresa);
        }
      } else throw new Error("Credenciales incorrectas?");
    } catch (e) {
      console.warn("No se pudo auth directa. !Reintentando?", {
        noReintentar,
      });
      if (noReintentar) {
        error = "Credenciales no encontradas";
      } else {
        console.warn(e);
        try {
          // Si hubo exito en un inicio de sesion previo con una cuenta maestra (admin), usarla como proxy
          let defaultUser = datos.defaultUser;
          let defaultPw = datos.defaultPw;
          if (defaultUser == "")
            throw new Error("No hay datos de sesión predeterminada");
          let previousDefaultUser = metodos.fetchLocal("wstDefaultUser");
          if (previousDefaultUser && previousDefaultUser.length > 0)
            defaultUser = previousDefaultUser;
          let previousDefaultPw = metodos.fetchLocal("wstDefaultPw");
          if (previousDefaultPw && previousDefaultPw.length > 0)
            defaultPw = previousDefaultPw;
          // Obtener un JWT
          jwtable = await metodos.login(
            defaultUser,
            defaultPw,
            dondeGuardarIdentidad,
            true
          );
          console.log("jwtable", defaultUser, defaultPw, jwtable);
          if (!(jwtable && jwtable.jwt)) {
            throw new Error({
              error: "Credenciales maestras incorrectas",
              raw: jwtable,
            });
          }
          metodos.saveLocal(dondeGuardarIdentidad, jwtable);
          let jwt = jwtable.jwt;
          // Buscar en la BD
          let resultsLegacy = await metodos.find(
            "usuario",
            ["email,eq," + user, "password,eq," + passOriginal],
            {
              limit: 1,
            }
          );
          let resultsLegacy2 = await metodos.find(
            "cliente",
            ["email,eq," + user, "password,eq," + passOriginal],
            {
              limit: 1,
            }
          );
          resultsLegacy = resultsLegacy.concat(resultsLegacy2);
          console.log("resultsLegacy", resultsLegacy);
          if (resultsLegacy && resultsLegacy[0] && resultsLegacy[0]._id) {
            console.info("AUTH+Legacy", resultsLegacy);
            // Permitir al usuario tener un token para entrar
            resultsLegacy[0].jwt = jwt;
            //toast("Concedido JWT para", resultsLegacy[0].email)
            //
            metodos.saveLocal(dondeGuardarIdentidad, resultsLegacy[0]);
            resultado = resultsLegacy[0];
          } else {
            console.error("EAUTH", resultsLegacy);
          }
        } catch (e) {
          error = e;
        }
      }
    }
    return new Promise(async (resolve, reject) => {
      await metodos.wait(100);
      if (resultado && resultado._id) {
        console.log("CREDENCIALES", resultado);
        // Si está disponible info de una empresa, guardarla de una vez
        try {
          let empresaExistente = (await metodos.get("empresa")) || [];
          console.log("");
          if (empresaExistente && empresaExistente[0])
            metodos.saveLocal("vueonic6.empresadb", empresaExistente[0]);
          //
        } catch (e) {
          console.error(e);
        }
        resolve(resultado);
      } else {
        console.error("CREDENCIALES", resultado);
        reject({
          error: "Error de autentificación",
          raw: error,
        });
      }
    });
  },
  logout: async function () {
    metodos.deleteLocal("yo");
    metodos.deleteLocal("menuArbol");
    // borrar los datos locales
    for (let key in localStorage) {
      // console.log("Comprobando", key);
      if (key.startsWith(prefijo) && key.split(".").length >= 3) {
        localStorage.removeItem(key);
        // console.log("Eliminado", key);
      }
    }
    //
    try {
      window.toast("Sesión cerrada");
      //let empresastr = metodos.fetchLocal("empresastr");
      let rutaLogin = metodos.fetchLocal("rutaLogin") || "/login";
      console.log("rutaLogin?", rutaLogin);
      //if (empresastr && empresastr != "") rutaLogin += "/" + empresastr;
      rutaLogin = "#" + rutaLogin;
      window.location.assign(rutaLogin);
      await metodos.wait(2000);
    } catch (e) {
      console.error(e);
    }
    window.location.reload();
  },
  uuid: function () {
    return uuidv4().replace(/-/g, "").substring(0, 24);
  },
  token: function () {
    let t = "",
      k = {};
    try {
      k = metodos.fetchLocal("yo");
      t = k.jwt || "";
      if (t == null || t == "") throw new Error("EHB3TOKENNOEXISTE");
    } catch (e) {
      // No se pudo recuperar el token
      k = JSON.parse(localStorage.getItem("wst.yo"));
      t = k ? k.jwt : "";
    }
    return t;
  },
  tokenHash: function () {
    const tiempo = new Date().toISOString().substring(0, 13);
    const hash = sha1(tiempo).substring(0, 6);
    if (!datos.modoProduccion) console.log("tokenHash?", tiempo, hash);
    return hash;
  },
  setServer(str = "") {
    //datos.servidor = str;
    //metodos.saveLocal("servidor", datos.servidor);
    console.log("setServer", str);
    return true;
  },
  setEmpresa(str = "") {
    let servidor = datos.servidorDefault.replace("test", str);
    return metodos.setServer(servidor);
  },
  // Funciones de almacenamiento local
  saveLocal: async function (lugar = "", obj) {
    if (typeof obj == "object")
      localStorage.setItem(prefijo + "." + lugar, JSON.stringify(obj));
    else localStorage.setItem(prefijo + "." + lugar, obj);
    return true;
  },
  fetchLocal: function (lugar = "") {
    lugar = prefijo + "." + lugar;
    let r = null;
    try {
      r = JSON.parse(localStorage.getItem(lugar));
    } catch (e) {
      r = localStorage.getItem(lugar);
    }
    return r;
  },
  deleteLocal: function (lugar = "") {
    console.log("deleteLocal", lugar);
    localStorage.removeItem(prefijo + "." + lugar);
    return true;
  },
  // Funciones de almacenamiento en Wanshitong
  get: function (modelo = "", id = "", anularTimeout = false, subset) {
    if (metodos.fetchLocal("forzarModoOffline") == true)
      return Promise.reject("get forzarModoOffline");
    return new Promise(async (resolve, reject) => {
      let ruta = datos.servidor + "/get/" + modelo;
      console.log("GET", modelo, id, ruta);
      if (id != "") {
        ruta += "/" + id;
      }
      ruta += "?ts=" + new Date().getTime();
      if (subset && typeof subset == "object")
        ruta += "&subset=" + btoa(JSON.stringify(subset));
      //else {
      let options = {
        headers: {
          "wst-appkey": appKey,
          token: metodos.token(),
        },
      };
      if (id != "" && !anularTimeout && defaultTimeout != 0) {
        options.timeout = defaultTimeout;
        //console.log("Timeout", id, options)
      }
      try {
        let o = await axios.get(ruta, options); //.then(function(o) {
        if (o && o.data) resolve(metodos.limpiarObjetos(o.data));
        else throw new Error("No hay datos para mostrar");
      } catch (e) {
        console.error("EAPIGET " + modelo, e);
        reject(e);
      }
    });
  },
  find: function (modelo = "", params = [], adicional = {}) {
    return new Promise((resolve, reject) => {
      let resultado = [];
      let enCache = false;
      let ruta = datos.servidor + "/get/" + modelo + "?";
      if (typeof params == "string") params = [params];
      if (params.length > 0) {
        params.forEach(function (param) {
          if (typeof param == "string") ruta += "filters[]=" + param + "&";
        });
      }
      if (typeof adicional.limit != "undefined")
        ruta += "limit=" + adicional.limit + "&";
      if (typeof adicional.skip != "undefined")
        ruta += "skip=" + adicional.skip + "&";
      if (typeof adicional.math != "undefined")
        ruta += "math=" + adicional.math + "&";
      if (typeof adicional.sort != "undefined") {
        for (let s in adicional.sort) {
          ruta += "sort=" + adicional.sort[s] + "&";
        }
      }
      if (adicional.fields?.length > 0)
        ruta += "fields=" + adicional.fields.join(",") + "&";
      if (typeof adicional.subset != "undefined")
        ruta += "subset=" + btoa(JSON.stringify(adicional.subset)) + "&";
      // Determinar si la consulta fue hecha previamente
      let candidato = "";
      candidato = localStorage.getItem("wstRuta." + btoa(ruta));
      if (
        candidato &&
        candidato.startsWith("[") &&
        candidato.length > 2 &&
        datos.tiempoCache > 0
      ) {
        enCache = true;
        const listaCache = JSON.parse(candidato);
        console.log("Consulta en caché. Rescatando.", listaCache.length);
        console.log(ruta);
        for (let c in listaCache) {
          const id = listaCache[c];
          if (datos.cache[modelo] && datos.cache[modelo][id])
            resultado.push(datos.cache[modelo][id]);
          else enCache = false;
        }
        console.warn("Cache", resultado);
        if (enCache) resolve(resultado);
      }
      // Hacer la consulta real
      try {
        axios
          .get(ruta, {
            headers: {
              "wst-appkey": appKey,
              token: metodos.token(),
            },
          })
          .then(function (o) {
            resolve(metodos.limpiarObjetos(o.data));
          })
          .catch(function (e) {
            console.error(e);
            reject(e);
          });
      } catch (e) {
        console.error("EAPIFIND", e);
        reject(e);
      }
    });
  },
  limpiarObjetos2: (o) => {
    return o;
  },
  limpiarObjetos: (objeto = "") => {
    const propsNoTraducibles = ["telefono", "celular", "email"];
    const propsParseablesAFloat = ["costo", "cantidad", "precio"];
    try {
      for (const prop in objeto) {
        let noEsTraducible = false;
        let debeSerParseadoAFloat = false;
        let esBooleano = ["true", "false"].indexOf(objeto[prop]) >= 0;
        const esFechaTraducible =
          prop.startsWith("fecha") && !isNaN(objeto[prop]);
        switch (typeof objeto[prop]) {
          case "string":
            for (const nt of propsNoTraducibles)
              if (prop.startsWith(nt)) noEsTraducible = true;
            for (const nt of propsParseablesAFloat)
              if (prop.startsWith(nt)) debeSerParseadoAFloat = true;
            if (esFechaTraducible) objeto[prop] = parseInt(objeto[prop]);
            else if (noEsTraducible) continue;
            else if (esBooleano) {
              objeto[prop] = objeto[prop] == "true";
            } else if (debeSerParseadoAFloat)
              try {
                objeto[prop] = parseFloat(objeto[prop]);
              } catch (e) {
                console.error("EPARSEFLOAT", prop, objeto[prop]);
              }
            // console.log("U?", objeto[prop], { prop, noEsTraducible, debeSerParseadoAFloat, esFechaTraducible,esBooleano });
            break;
          case "object":
            if (Array.isArray(objeto[prop]))
              objeto[prop] = [...objeto[prop].map(metodos.limpiarObjetos)];
            else objeto[prop] = metodos.limpiarObjetos(objeto[prop]);
            break;
          default:
            break;
        }
      }
    } catch (e) {
      console.error(",,,,,", e, objeto);
    }
    return objeto;
  },
  save: function (modelo = "", objeto, forzarbd = true) {
    modelo = modelo || "";
    objeto = objeto || {};
    //console.log("Save", modelo, objeto)
    if (metodos.fetchLocal("forzarModoOffline") == true)
      return Promise.reject("save forzarModoOffline");
    if (typeof objeto.__v != "undefined") delete objeto.__v;
    if (typeof objeto.jwt != "undefined") delete objeto.jwt;
    if (!datos.modoOffline || forzarbd) {
      let vm = this;
      if (!objeto.fecha) objeto.fecha = new Date().getTime();
      return new Promise((resolve, reject) => {
        if (objeto == null || modelo == "")
          reject("Debes especificar un modelo y un objeto a actualizar");
        let ruta = datos.servidor + "/upsert/" + modelo;
        //
        if (!objeto._id?.length) {
          // Insertar
          axios
            .post(ruta, objeto, {
              headers: {
                "wst-appkey": appKey,
                token: metodos.token(),
                "content-type": "application/json",
              },
            })
            .then(
              function (o) {
                console.log("Insertado", modelo, o.data.objeto);
                resolve(o.data.objeto);
              },
              function (e) {
                vm.errorHandler(e);
                reject(e);
              }
            );
        } else {
          // Actualizar
          ruta += "/" + objeto._id;
          axios //.put
            .post(ruta, objeto, {
              headers: {
                "wst-appkey": appKey,
                token: metodos.token(),
                "content-type": "application/json",
              },
            })
            .then(
              function (o) {
                console.log("Actualizado", modelo, o.data);
                resolve(o.data.objeto);
              },
              function (e) {
                vm.errorHandler(e);
                reject(e);
              }
            );
        }
      });
    } else return ApiOffline.save(modelo, objeto);
  },
  saveMulti: async function (modelo = "", objetos = [], chunkSize = 20) {
    console.log("saveMulti", modelo, objetos.length, chunkSize);
    const ruta = datos.servidor + "/upsertmulti/" + modelo;
    objetos = (objetos || []).filter((o) => o._id);
    if (objetos == [] || modelo == "") return false;
    console.log("limpiando...");
    for (let id in objetos) {
      if (typeof objetos[id].__v != "undefined") delete objetos[id].__v;
      if (typeof objetos[id].jwt != "undefined") delete objetos[id].jwt;
      if (!objetos[id].fecha) objetos[id].fecha = new Date().getTime();
    }
    const errorHandler = this.errorHandler;
    let i, j;
    let resultado = [];
    const progresable = objetos.length;
    let progresado = 0;
    console.log("partiendo en pedazos de elementos:", chunkSize);
    for (i = 0, j = objetos.length; i < j; i += chunkSize) {
      const chunk = objetos.slice(i, i + chunkSize);
      resultado.push(
        await axios
          //.put
          .post(ruta, chunk, {
            headers: {
              "wst-appkey": appKey,
              token: metodos.token(),
            },
          })
          .then(
            function (o) {
              //console.log("saveMulti insertados", modelo, o.data);
              progresado += o.data.objetos.length;
              return o.data;
            },
            function (e) {
              errorHandler(e);
              return false;
            }
          )
      );
      console.log("saveMulti progreso", modelo, progresado, progresable);
    }
    return resultado;
  },
  delete: function (modelo = "", id) {
    modelo = modelo || "";
    id = id || null;
    if (metodos.fetchLocal("forzarModoOffline") == true)
      return Promise.reject("delete forzarModoOffline");
    if (!datos.modoOffline) {
      let ruta = datos.servidor + "/" + modelo + "/" + id;
      if (typeof ApiLocalStorage != "undefined")
        ApiLocalStorage.delete(modelo, id);
      return axios.delete(ruta, {
        headers: {
          "wst-appkey": appKey,
          token: metodos.token(),
        },
      });
    } else return ApiOffline.delete(modelo, id);
  },
  deleteMulti: function (modelo = "", lista) {
    lista = lista || [];
    modelo = modelo || "";
    if (metodos.fetchLocal("forzarModoOffline") == true)
      return Promise.reject("deletemulti forzarModoOffline");
    if (!datos.modoOffline) {
      const ruta =
        datos.servidor + "/multi/" + modelo + "/" + JSON.stringify(lista);
      return axios.delete(ruta, {
        headers: {
          "wst-appkey": appKey,
          token: metodos.token(),
        },
      });
    } else {
      return ApiOffline.deleteMulti(modelo, lista);
    }
  },
  dump: function (bd = "") {
    console.error("EWARNDBDUMP", new Date());
    return new Promise((resolve, reject) => {
      let ruta = datos.servidor + "/dump";
      if (bd != "") ruta += "/" + bd;
      axios
        .post(ruta, null, {
          headers: {
            "wst-appkey": appKey,
            token: metodos.token(),
          },
        })
        .then(function (p) {
          let o = p.data || {};
          if (!o.error) resolve(o);
          else reject(o);
        });
    });
  },
  // Manipulacion de archivos e imagenes
  upload: function (
    handler = "#archivo",
    devolver = "",
    nombreAlternativo = null,
    omitirHash = false,
    webp = true
  ) {
    return new Promise((resolve, reject) => {
      if (["", "binario", "texto", "base64"].indexOf(devolver) == -1)
        reject('Valor de devolución no valido ("", binario, texto o base64)');
      else if (handler == "")
        reject("Debes especificar un query DOM en el primer argumento");
      let identificador = handler;
      let h = document.querySelector(identificador).files[0];
      console.log("Cargando archivo del identificador", identificador);
      let fr = new FileReader();
      if (devolver == "base64") fr.readAsDataURL(h);
      else if (devolver == "texto") fr.readAsText(h, "utf-8");
      // if(devolver=='' || devolver=='binario')
      else fr.readAsBinaryString(h);
      fr.onloadend = function () {
        let binario = fr.result;
        console.log("Bin", handler, binario.length);
        let ruta = datos.servidor + "/upload";
        if (devolver != "") resolve(binario);
        else
          axios
            .post(
              ruta,
              {
                archivo: btoa(binario),
                tipo: h.type,
                nombre: h.name,
                omitirHash,
                nombreAlternativo,
                webp: webp == true ? 1 : 0,
              },
              {
                headers: {
                  "wst-appkey": appKey,
                  "content-type": "application/json",
                  token: metodos.token(),
                },
              }
            )
            .then(
              (o) => {
                resolve(o.data.filename);
              },
              (e) => {
                reject(e);
              }
            );
      };
      console.info("FileReader", h);
    });
  },

  uploadFormData: function (
    handler,
    devolver = "",
    nombreAlternativo = null,
    omitirHash = false,
    webp = true
  ) {
    return new Promise((resolve, reject) => {
      if (handler == "")
        reject(
          "Debes especificar un query DOM en el primer argumento" + devolver
        );
      let identificador = handler;
      let h = document.querySelector(identificador).files[0];
      console.log("Cargando archivo del identificador", identificador);
      let ruta = datos.servidor + "/upload";
      const formData = new FormData();
      formData.append("file", h);
      if (nombreAlternativo) {
        formData.append("nombreAlternativo", nombreAlternativo);
      }
      formData.append("omitirHash", omitirHash ? 1 : 0);
      formData.append("webp", webp ? 1 : 0);

      axios
        .post(ruta, formData, {
          headers: {
            "wst-appkey": appKey,
            "content-type": "multipart/form-data",
            token: metodos.token(),
          },
        })
        .then((o) => {
          resolve(o.data.filename);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  obtenerArchivo: async function (file = "", devolverB64 = false) {
    const url = metodos.urlize(file);
    file = (
      await metodos.proxied2({
        url,
        method: "get",
        responseType: "arraybuffer",
      })
    ).data.data;
    console.log("FILE b64", file.length);
    if (true == devolverB64) return file;
    file = btoa(String.fromCharCode(...new Uint8Array(file)));
    file = "0," + file;
    console.log("FILE", file);
    return file;
  },
  removeFile: function (nombre) {
    if (!(nombre && nombre != ""))
      return Promise.reject("Debe especificar el nombre del archivo");
    else {
      let ruta = datos.servidor + "/removefile/" + nombre;
      return axios.post(ruta, null, {
        headers: {
          "wst-appkey": appKey,
          token: metodos.token(),
        },
      });
    }
  },
  rotator: function (foto) {
    return new Promise((resolve, reject) => {
      if (foto.indexOf(datos.cdn) >= 0)
        foto = foto.replace(datos.cdn + "/", "");
      console.log("Pidiendo rotación de", foto);
      if (!foto || foto == "")
        reject({
          error: "No fue especificada la foto a girar",
        });
      axios
        .post(
          datos.servidor + "/rotator",
          {
            img: foto,
          },
          {
            headers: {
              "wst-appkey": appKey,
              token: metodos.token(),
            },
          }
        )
        .then((p) => {
          if (p && p.data && p.data.nombre) resolve(p.data.nombre);
          else
            reject({
              error: "No se pudo obtener la nueva imagen rotada",
              raw: p,
            });
        })
        .catch((e) => {
          reject({
            error: "No se pudo recuperar la imagen rotada",
            raw: e,
          });
        });
    });
  },
  thumbnailer: function (handler, ratio, nombre, tamano, devolver) {
    devolver = devolver || "";
    ratio = ratio || 1;
    nombre = nombre || "";
    tamano = tamano || 500;
    return new Promise((resolve, reject) => {
      if (["", "binario", "base64"].indexOf(devolver) == -1)
        reject('Valor de devolución no valido ("", binario o base64)');
      const identificador = "#" + handler;
      let h = document.querySelector(identificador).files[0];
      console.log("Cargando archivo del identificador", identificador);
      let fr = new FileReader();
      if (devolver == "base64") fr.readAsDataURL(h);
      // if(devolver=='' || devolver=='binario')
      else fr.readAsBinaryString(h);
      fr.onloadend = function () {
        let binario = fr.result;
        console.log("Bin", handler, binario.length);
        let ruta = datos.servidor + "/thumbnailer";
        if (devolver != "") resolve(binario);
        else
          axios
            .post(
              ruta,
              {
                imagen: btoa(binario),
                tipo: h.type,
                nombre: nombre || h.name,
                tamano: tamano,
                ratio: ratio,
              },
              {
                headers: {
                  "wst-appkey": appKey,
                  token: metodos.token(),
                },
              }
            )
            .then(
              async (o) => {
                console.log("Thumbnailer?", o.data);
                let img = o.data.url;
                try {
                  await axios.get(datos.cdn + "/" + o.data.url);
                  resolve(img);
                } catch (e) {
                  img = img.replace("_thumb", "");
                  //      resolve(await vm.upload(handler))
                }
                console.log("thumbnailer", img);
                resolve(img);
              },
              (e) => {
                reject(e);
              }
            );
      };
      console.info("FileReader", h);
    });
  },
  download: function (str = "") {
    let ruta =
      str.indexOf("http") >= 0 ? datos.servidor + "/download/" + str : str;
    if (!datos.modoOffline) {
      return axios.get(ruta, {
        headers: {
          "wst-appkey": appKey,
          token: metodos.token(),
        },
      });
    } else {
      return Promise.reject({
        error: "No se puede usar el CDN en modo Offline",
      });
    }
  },
  downloadBase64: function (ruta) {
    if (!datos.modoOffline) {
      return new Promise((resolve, reject) => {
        metodos.download(ruta).then(
          (obj) => {
            const header = "data:application/binary;base64,";
            resolve(header + obj.data.data);
          },
          (err) => {
            reject(err);
          }
        );
      });
    } else {
      return Promise.reject({
        error: "No se puede usar el CDN en modo Offline",
      });
    }
  },
  downloadForce: function (ruta) {
    if (!datos.modoOffline) {
      metodos.downloadBase64(ruta).then((blob) => {
        let a = document.createElement("a");
        a.href = blob;
        a.download = ruta;
        a.style.display = "none";
        a.style.visibility = "hidden";
        document.querySelector("body").appendChild(a);
        a.click();
      });
    } else {
      return Promise.reject({
        error: "No se puede usar el CDN en modo Offline",
      });
    }
  },
  // Email
  email: function (obj) {
    console.log("Enviando correo", modoProduccion, obj);
    if (false == modoProduccion) {
      window.toast(`Email dummy ${obj.emailDestino}`, 5000, "danger");
      return Promise.resolve(true);
    }
    return new Promise(async (resolve, reject) => {
      const paramsRequeridos = [
        "emailOrigen",
        "nombreOrigen",
        "mensaje",
        "emailDestino",
      ];
      let paramsFaltantes = [];
      for (let param of paramsRequeridos)
        if (!obj[param]) paramsFaltantes.push(param);
      if (paramsFaltantes.length > 0)
        reject({
          error: "Faltan datos",
          raw: paramsFaltantes.join(","),
        });
      else
        try {
          const empresastr = (await metodos.fetchLocal("empresastr")) ?? "";
          const ruta = (datos.servidor + "/node/email").replace("/encorp", "");
          console.log({ empresastr, ruta });
          axios
            .post(ruta, obj, {
              headers: {
                "wst-appkey": appKey,
                //token: metodos.token(),
              },
            })
            .then((r) => {
              resolve(r);
            })
            .catch((e) => {
              reject(e);
            });
        } catch (e) {
          console.error("ECORREO", e);
          reject({
            error: "No se pudo enviar el correo",
            raw: e,
          });
        }
    });
  },
  whatsapp: function (props = {}) {
    const destino = props.destino
      .toString()
      .trim()
      .replace(/-/g, "")
      .replace(/ /g, "");
    if (destino.length != 10)
      return Promise.reject("No fue especificado destino");
    const mensaje = props.mensaje || props.archivo;
    if (!mensaje) return Promise.reject("No fue especificado mensaje");
    const ruta = `${datos.servidor}/../node/waclient/msgs`;
    const obj = {
      message: props.mensaje,
      media: props.archivo,
      phonenumber: destino,
      filename: props.filename,
    };
    console.log("Enviando", { ...obj, ruta });
    return axios.post(ruta, obj, {
      headers: {
        "wst-appkey": appKey,
        //token: metodos.token(),
      },
    });
  },
  arrayBufferToBase64(buffer) {
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  },
  //
  nonce: async function (str = "") {
    const url = `${protocolo}://${dominioMaestro}:${puertoMaestro}/nonce.php?b=${btoa(
      str
    )}`;
    return (await axios.get(url)).data;
  },
  quickpdf: async function (
    html = "",
    pdfOptions = {
      format: "a4", // Si se establece width y height, hay que omitir format
      margin: {
        top: "10mm",
        bottom: "10mm",
        left: "10mm",
        right: "10mm",
      },
    },
    usePuppeteer = true,
    customCss = "",
    guardarPDF = false
  ) {
    let url = `${protocolo}://${dominioMaestro}:${puertoMaestro}/node/html2pdf`;
    if (customCss == "")
      customCss =
        "@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');";
    customCss += "* { font-family: 'Ubuntu', sans-serif; }"; //*/
    //
    return axios.post(
      url,
      {
        html,
        pdfOptions,
        usePuppeteer,
        customCss,
        guardarPDF,
      },
      {
        headers: {
          "wst-appkey": appKey,
          token: metodos.token(),
        },
      }
    );
  },
  // libreofficetopdf
  libreofficetopdf: async function (archivo = "") {
    if (typeof archivo != "string")
      archivo = metodos.arrayBufferToBase64(archivo);
    let ruta = datos.servidor + "/libreofficetopdf";
    if (archivo == "")
      return Promise.reject({
        error: "No fue especificado un archivo a convertir",
      });
    else
      return axios.post(ruta, {
        archivo,
      });
  },
  // Funciones por proxy, usando axios de lado servidor
  proxied: function (obj) {
    let ruta = datos.servidor + "/proxied";
    let params = ["url", "options", "params"];
    // params.REQUEST_TYPE
    for (let param of params)
      if (!obj[param])
        return Promise.reject("Falta el parámetro axios:", param);
    if (!datos.modoOffline) {
      return axios.post(ruta, obj, {
        headers: {
          "wst-appkey": appKey,
          token: metodos.token(),
        },
      });
    } else {
      return Promise.reject({
        error: "No se puede usar el proxy de aplicaciones en modo Offline",
      });
    }
  },
  proxied2: function (obj) {
    const ruta = datos.servidor + "/proxied2";
    return axios.post(ruta, obj, {
      headers: {
        "wst-appkey": appKey,
        token: metodos.token(),
        "Content-Type": "application/json",
      },
    });
  },
  // QR
  qrcode: function (str) {
    console.log("QR", str);
    let ruta = datos.servidor + "/qrcode";
    if (!datos.modoOffline) {
      return axios.post(
        ruta,
        {
          cadena: str,
        },
        {
          headers: {
            "wst-appkey": appKey,
            token: metodos.token(),
          },
        }
      );
    } else {
      return Promise.reject({
        error: "No se puede usar el módulo QR en modo Offline",
      });
    }
  },
  // Geo
  geolocate: function () {
    let y = metodos.fetchLocal("yo") || {};
    if (metodos.fetchLocal("forzarModoOffline") == true)
      return Promise.reject("geolocate forzarModoOffline");
    return new Promise(async (resolve, reject) => {
      if ("geolocation" in navigator && y && y._id) {
        navigator.geolocation.getCurrentPosition(
          async (pos) => {
            let c = {
              rol: y.rol || "invitado",
              userid: y._id || "",
              lat: pos.coords.latitude,
              long: pos.coords.longitude,
              date: new Date().getTime(),
              route: window.location.hash,
            };
            let u = await metodos.save("geo", c);
            console.log("Guardado geolocalizacion", u);
            resolve(u);
          },
          (e) => {
            reject({
              error: "No se pudo obtener la ubicación",
              raw: e,
            });
          }
        );
      } else {
        reject("No se pudo acceder al módulo de geolocalización");
      }
    });
  },
  //
  readBypass: function (coleccion = "") {
    const ruta = datos.servidor + "/readbypass/" + coleccion;
    return axios.post(
      ruta,
      {},
      {
        headers: {
          "wst-appkey": appKey,
          //token: metodos.token(),
        },
      }
    );
  },
  encorpRegistro: function (usuario = {}) {
    const ruta = datos.servidor + "/encorpregistro";
    return axios.post(ruta, usuario, {
      headers: {
        "wst-appkey": appKey,
      },
    });
  },
  // Variables globales
  varGlobal(str = "", valorDefault = "") {
    let variable = valorDefault;
    const empresa = metodos.fetchLocal("empresadb") || {};
    //console.log("varglobal", str, valorDefault, empresa.variables);
    if (
      empresa &&
      empresa.variables &&
      empresa.variables[str] &&
      empresa.variables[str] != ""
    )
      variable = empresa.variables[str];
    return variable;
  },
  urlize: (str = "") =>
    str.startsWith("data") || str.startsWith("http") ? str : cdn + "/" + str,
};

export default {
  ...datos,
  ...metodos,
};
</script>
