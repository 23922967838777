//const rutaLocal = "@/components/RecepcionGastos/";
const rutaLocal = "./";
import Api from "@/components/Api";
const routes = [
  // Rutas de operación
  {
    path: "/recepciongastos",
    redirect: "/recepciongastos/home",
  },
  {
    path: "/recepciongastos/home",
    name: "RecepcionGastosHome",
    component: () =>
      import(/* webpackChunkName: "rghome" */ rutaLocal + "Menu"),
  },
  {
    path: "/recepciongastos/gastos",
    name: "RecepcionGastosMenuPresupuestos",
    component: () =>
      import(
        /* webpackChunkName: "rgmenupresupuestos" */ rutaLocal + "MenuGastos"
      ),
  },
  {
    path: "/recepciongastos/justificacion",
    name: "RecepcionGastosJustificacion",
    component: () =>
      import(
        /* webpackChunkName: "rgjustificacion" */ rutaLocal +
          "Presupuestos/Justificacion"
      ),
  },
  {
    path: "/recepciongastos/aprobacion",
    name: "RecepcionGastosAprobacion",
    component: () =>
      import(
        /* webpackChunkName: "rgaprobacion" */ rutaLocal +
          "Presupuestos/Aprobaciones"
      ),
  },
  {
    path: "/recepciongastos/pagos",
    name: "RecepcionGastosPagos",
    component: () =>
      import(
        /* webpackChunkName: "rgpagos" */ rutaLocal + "Presupuestos/Pagos"
      ),
  },
  {
    path: "/recepciongastos/catalogos",
    name: "RecepcionGastosCatalogos",
    component: () =>
      import(
        /* webpackChunkName: "rgcatalogos" */ rutaLocal + "Catalogos/Catalogos"
      ),
  },
  {
    path: "/recepciongastos/catalogos/proveedores",
    redirect: "/admin/proveedores",
  },
  {
    path: "/recepciongastos/catalogos/fechaspago",
    name: "RecepcionGastosFechasPago",
    component: () =>
      import(
        /* webpackChunkName: "rgcatalogofechapago" */ rutaLocal +
          "Catalogos/Fechaspago"
      ),
  },
  {
    path: "/recepciongastos/catalogos/conceptossat",
    name: "RecepcionGastosConceptosSat",
    component: () =>
      import(
        /* webpackChunkName: "rgCatalogoConceptosSat" */ rutaLocal +
          "Catalogos/Conceptossat"
      ),
  },
  {
    path: "/recepciongastos/catalogos/conceptossat/:id",
    name: "RecepcionGastosConceptoSat",
    component: () =>
      import(
        /* webpackChunkName: "rgCatalogoConceptoSatId" */ rutaLocal +
          "Catalogos/Conceptosat"
      ),
    props: true,
  },
  {
    path: "/recepciongastos/catalogos/documentosproveedores",
    name: "RecepcionGastosDocuemntoProveedor",
    component: () =>
      import(
        /* webpackChunkName: "rgCatalogoDocumentosProveedores" */ rutaLocal +
          "Catalogos/Documentosproveedores"
      ),
  },
  {
    path: "/recepciongastos/catalogos/documentosproveedores/:id",
    name: "RecepcionGastosDocumentoProveedorId",
    component: () =>
      import(
        /* webpackChunkName: "rgCatalogoDocumentoProveedorID" */ rutaLocal +
          "Catalogos/Documentoproveedor"
      ),
    props: true,
  },
  {
    path: "/recepciongastos/catalogos/corporativos",
    name: "RecepcionGastosCorporativos",
    component: () =>
      import(
        /* webpackChunkName: "rgcatalogocorporativos" */ rutaLocal +
          "Catalogos/Corporativos"
      ),
  },
  {
    path: "/recepciongastos/solicitudfactura",
    name: "RecepcionGastosSolicitudesFactura",
    component: () =>
      import(
        /* webpackChunkName: "rgsolicituesfactura" */ rutaLocal +
          "SolicitudFactura/SolicitudesFactura"
      ),
  },
  {
    path: "/recepciongastos/solicitudfactura/:id",
    name: "RecepcionGastosSolicitudFacturaId",
    component: () =>
      import(
        /* webpackChunkName: "rgsolicitudfacturaid" */ rutaLocal +
          "SolicitudFactura/SolicitudFactura"
      ),
    props: true,
  },
  // Rutas de acceso a proveedor
  {
    path: "/proveedor/misventas",
    name: "ProveedorMisVentas",
    component: () =>
      import(
        /* webpackChunkName: "proveedormisventas" */ rutaLocal +
          "Proveedores/MisVentas"
      ),
  },
  {
    path: "/proveedor/misdatos",
    redirect: "/proveedor/cuenta",
  },
  {
    path: "/proveedor/cuenta",
    name: "ProveedorMisDatos",
    component: () =>
      import(
        /* webpackChunkName: "proveedormisdatos" */ rutaLocal +
          "Proveedores/MisDatos"
      ),
  },
  {
    path: "/proveedor/subirfactura",
    name: "ProveedorSubirFactura",
    component: () =>
      import(
        /* webpackChunkName: "proveedorsubirfactura" */ rutaLocal +
          "Proveedores/SubirFactura"
      ),
  },
];
const manifiesto = {
  // El nombre del módulo, como aparece en menús y en permisos
  nombre: "Recepción de gastos",
  // descripcion aparece en el menú
  descripcion: "Permite recibir facturas de proveedores y gestionar sus pagos",
  tarifa: 0,
  // Ícono principal
  //icono: "wallet",
  icono: "proveedores",
  // Permisos. Configurables desde /config/usuarios/:idUsuario
  permisosPosibles: [],
  // Al hacer clic en el botón en HelloWorld, la app redirigirá aquí
  rutaPrincipal: "/recepciongastos",
  // menuArbol alberga los links que se generan en el menú
  menuArbol: [
    {
      ruta: "/recepciongastos",
      label: "Recepción de gastos",
      permiso: "Puede crea tareas",
      icono: "walletOutline",
    },
    {
      label: "",
    },
    {
      ruta: "/recepciongastos/catalogos",
      label: "Catálogos",
      permiso: "Puede crea tareas",
      icono: "appsOutline",
    },
  ],
  menuRol: {
    proveedor: [
      {
        ruta: "/proveedor/misventas",
        label: "Mis ventas y facturas",
        icono: "fileTrayFullOutline",
      },
      {
        ruta: "/proveedor/cuenta",
        label: "Mis datos",
        icono: "listCircleOutline",
      },
      {
        ruta: "/proveedor/subirfactura",
        label: "Subir factura",
        icono: "cloudUploadOutline",
      },
    ],
  },
  // Variables globales para el módulo, configurables desde /config/empresa.
  // Si existe esta variable en el usuario, se sobrepondrá a la variable global de empresa.
};
let menuArbol = Api.fetchLocal("menuArbol") || {};
menuArbol[manifiesto.nombre] = manifiesto;
Api.saveLocal("menuArbol", menuArbol);
export default routes;

/*

Instrucciones:

- Convertir Vue() a defineComponent()
- Cambiar div.ion-page a IonPage
- Importar todos los componentes
- Cambiar todos los íconos a IonIcon, importarlos desde 'ionicons/icons' y exportarlos en data()
- Importar IonMenuButton y cambiarlo a slot="start"
- Cambiar los modelos de Vue() a standalone {...datos,...metodos}
- Filtros depreciados!
  - import {format} from 'date-fns'
  - DD cambia a dd
  - yyyy cambia a yyyy
- Ya que Wanshitong guarda fechas actualas, guardar TODAS las fechas secundarias en el formato nativo de su input
- Todos los input, checkbox y cualquier cosa que use $event DEBE ser importado
- IonContent debe ser importado o no habrá scroll!
- Todos los eventos tendrán que pasarse a los tags de los componentes
- setup() NO puede ser asíncrono!

*/
