<script>
import axios from "axios";
import sha1 from "sha1";
import LZString144 from "./lzstring";

let datos = {
  appName: "ITTONI APP",
  esBdLocal: true,
  servidor: "https://app.ITTONI.mx:8082/test",
  servidorDefault: "https://app.ITTONI.mx:8082/test",
  //cdn: 'http://45.55.89.128/cdn/hb3',
  cdn: "https://cdn.ITTONI.mx",
  yo: {},
  yoGoogle: {},
  WSTcolecciones: ["Producto", "Etiqueta"],
  cache: {},
  usarCache: false,
  modoPersistente: false,
  defaultUser: "admin@local",
  defaultPw: "password",
  customTokenSource: "",
  localStorageDefaultKey: "yo",
  modoProduccion: import.meta?.env?.NODE_ENV === "production",
  modoOffline: false,
  usarPrecache: false,
  // Modo localStorage
  prefijo: "ittoniapp3",
  comprimirDatos: true,
  habilitarGeolocalizacion: false,
};
let metodos = {
  login: async function (user = "", pass = "", dondeGuardarIdentidad) {
    dondeGuardarIdentidad =
      dondeGuardarIdentidad || datos.localStorageDefaultKey;
    console.log("LOGIN", user, dondeGuardarIdentidad);
    console.log("Guardando identidad en ", dondeGuardarIdentidad);
    if (dondeGuardarIdentidad != datos.localStorageDefaultKey) {
      datos.customTokenSource = dondeGuardarIdentidad;
    }
    if (pass.length != 40) pass = sha1(pass || "");
    let resultado = null;
    let error = null;
    console.info("Login", user, pass);
    try {
      console.log("Intentando auth directa...");
      const paramsBusquedaUsuario = ["email,eq," + user, "password,eq," + pass];
      let rJWT = (
        await metodos.find("directorio", paramsBusquedaUsuario)
      ).concat(await metodos.find("usuario", paramsBusquedaUsuario));
      rJWT = {
        data: rJWT[0],
      };
      rJWT.data.jwt = sha1(
        "Mi vieja mula ya no es lo que era ya no es lo que era ya no es lo que era"
      );
      //let rJWT2 = (await Api.find('usuario', paramsBusquedaUsuario))
      console.log("try?", paramsBusquedaUsuario, rJWT);
      if (rJWT && rJWT.data && rJWT.data._id && rJWT.data.jwt) {
        metodos.saveLocal(dondeGuardarIdentidad, rJWT.data);
        // guardar contraseña maestra
        if (rJWT.data.rol && rJWT.data.rol == "administrador") {
          console.log("Guardando contraseña maestra", rJWT.data.email);
          metodos.saveLocal("wstDefaultUser", user);
          metodos.saveLocal("wstDefaultPw", pass);
        }
        resultado = rJWT.data;
        console.info("AUTH+JWT", resultado.email, resultado.jwt.length);
      } else {
        throw new Error("Credenciales incorrectas?");
      }
    } catch (e) {
      error = "Credenciales no encontradas";
    }
    return new Promise(async (resolve, reject) => {
      if (resultado && resultado._id) {
        console.log("CREDENCIALES", resultado);
        // Si está disponible info de una empresa, guardarla de una vez
        try {
          const empresaExistente = (await metodos.get("empresa")) || [];
          if (empresaExistente && empresaExistente[0]) {
            metodos.saveLocal("empresastr", empresaExistente[0].nombre);
            metodos.saveLocal("empresadb", empresaExistente[0]);
          }
          //
        } catch (e) {
          console.error(e);
        }
        resolve(resultado);
      } else {
        console.error("EAPILOCALSTORAGEAUTH", resultado);
        reject({
          error: "Error de autentificación",
          raw: error,
        });
      }
    });
  },
  // Servidor intercambiable
  cambiarServidor: function (url) /**/ {
    datos.servidor = url;
    const uurl = new URL(this.servidor);
    if (uurl.hostname != window.location.hostname && this.modoProduccion) {
      console.log(
        "Cambiando hostname",
        window.location.hostname,
        uurl.hostname
      );
      datos.servidor = datos.servidor
        .replace(uurl.hostname, window.location.hostname)
        .replace(uurl.protocol, window.location.protocol);
    }
    console.log("Cambiada la URL del servidor", this.servidor);
  },
  setServer: function (url, defaultUser, defaultPw) /**/ {
    datos.servidor = url;
    if (defaultUser) datos.defaultUser = defaultUser;
    if (defaultPw) datos.defaultPw = defaultPw;
    console.log(
      "Configurado servidor de esta instancia",
      datos.servidor,
      datos.defaultUser
    );
    //return this.ping()
    return true;
  },
  errorHandler: function (e) /**/ {
    let text = "";
    if (e && e.text) text = ": " + e.text;
    datos.error = "Error" + text;
    setTimeout(function () {
      delete datos.error;
    }, 1000);
    //    reject(e || {});
  },
  saveLocal: async function (lugar, obj) /**/ {
    console.log("saveLocal", lugar, obj);
    if (typeof obj == "object")
      localStorage.setItem(lugar, JSON.stringify(obj));
    else localStorage.setItem(lugar, obj);
    //
    return true;
  },
  fetchLocal: async function (lugar) /**/ {
    let r = null;
    try {
      r = JSON.parse(localStorage.getItem(lugar));
    } catch (e) {
      r = localStorage.getItem(lugar);
    }
    return r;
  },
  deleteLocal: function (lugar) /**/ {
    //console.log("deleteLocal", lugar)
    localStorage.removeItem(lugar);
  },
  esMovil: function () /**/ {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  },
  wait: function (time) /**/ {
    time = time || 1000;
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  },
  ping: function () {
    return Promise.resolve(true);
  },
  uuid: function () {
    // TODO remplazar primer mitad del UUID con algo característico del dispositivo o del nombre de la BD
    return "xxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      let r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
  dump: function (bd = "") {
    console.error("EWARNDBDUMP", new Date());
    const token = datos.token;
    const servidor = datos.servidor;
    return new Promise((resolve, reject) => {
      const ruta = servidor + "/dump";
      if (bd != "") ruta + "/" + bd;
      axios
        .post(ruta, null, {
          headers: {
            "wst-appkey": "vueonic",
            token: token,
          },
        })
        .then(function (p) {
          let o = p.data || {};
          if (!o.error) resolve(o);
          else reject(o);
        });
    });
  },
  token: function () {
    let t = "";
    const dondeLeerIdentidad =
      this.customTokenSource && this.customTokenSource != ""
        ? this.customTokenSource
        : this.localStorageDefaultKey;
    try {
      const k = JSON.parse(localStorage.getItem(dondeLeerIdentidad));
      t = k.jwt || "";
      if (t == null || t == "")
        throw new Error("EHB3TOKEN " + dondeLeerIdentidad);
    } catch (e) {
      // No se pudo recuperar el token
      const k = JSON.parse(localStorage.getItem("wst.yo"));
      t = k ? k.jwt : "";
    }
    return t;
  },
  get: function (coleccion, id, subset) /**/ {
    coleccion = (coleccion || "").toLowerCase();
    id = id || "";
    const comprimirDatos = this.comprimirDatos;
    let res;
    //console.log("ApiLocalStorage get", coleccion, id, subset)
    return new Promise(async (resolve, reject) => {
      try {
        //console.log("ApiLocalStorage get", coleccion, id, id != "");
        const keys = Object.keys(localStorage);
        let results = [];
        for (const key of keys) {
          let label = this.prefijo + "." + coleccion;
          //console.log("COMP", coleccion, label, key);
          if (id != "") label += "." + id;
          if (
            (key.startsWith(label) &&
              key.replace(label + ".", "").length == 24) ||
            key == label
          ) {
            let val = localStorage.getItem(key);
            if (comprimirDatos && !val.startsWith("{"))
              val = LZString144.decompress(val);
            try {
              val = JSON.parse(val);
              results.push(val);
            } catch (e) {
              // console.error("EJSON", e)
            }
          }
        }
        if (results.length == 0) {
          //throw new Error('Objeto no encontrado: ' + coleccion + ' ' + id)
          results = [];
          //console.error("Coleccion vacia", coleccion);
        }
        // Subset
        if (subset && typeof subset == "object") {
          const paramsRequeridos = ["coleccion", "query", "sustituir"];
          for (const id in results) {
            for (const sub of subset) {
              // Checar integridad del query del subset
              for (const param of paramsRequeridos)
                if (!(sub[param] && sub[param].length > 0))
                  throw new Error("Faltan parámetros del subset: " + param);
              // Construir el query
              let subres;
              const sustituir = results[id][sub.sustituir];
              if (sub.query.startsWith("_id") || sub.unico == true) {
                try {
                  subres = await metodos.get(sub.coleccion, sustituir);
                } catch (e) {
                  subres = {};
                }
              } else {
                let query = sub.query;
                if (!query.endsWith(",")) query += ",";
                if (query.split(",").length <= 1) query += ",eq,";
                try {
                  subres = await metodos.find(sub.coleccion, [
                    query + sustituir,
                  ]);
                } catch (e) {
                  subres = [];
                }
              }
              // Añadir al objeto del resultado
              results[id][sub.alias || sub.coleccion] = subres;
            }
          }
        }
        //
        //console.log("ApiLocalStorage Devolviendo", coleccion, id)
        if (
          id != ""
          //&& results.length == 1
        ) {
          res = results[0] || {};
        } else {
          res = results;
        }
        // console.log("results?", coleccion, res)
        resolve(res);
      } catch (e) {
        const yoGoogle = this.fetchLocal("firebaseAuth") || {};
        if (!yoGoogle.credential) console.error("EGET", coleccion, id, e);
        else console.info("EGET", e);
        if (id == "") {
          res = [];
          resolve(res); // Devolver coleccion vacia, es valor posible
        } else {
          console.error("EAPILOCALSTORAGENOTFOUND", coleccion, id, e);
          res = {
            // Devolver objeto no encontrado
            error: e,
          };
          reject(res);
        }
      }
    });
  },
  find: async function (coleccion, params, opciones) /**/ {
    let resultados = await this.get(coleccion);
    //console.log("find resultados sin filtrar?", coleccion, resultados)
    params = params || [];
    opciones = opciones || {};
    // Filtrar por parámetros
    if (params.length > 0) {
      for (const parametro of params) {
        // Parametrizar el filtro
        const campo = parametro.split(",")[0];
        const operador = parametro.split(",")[1];
        let valor = parametro.split(",")[2];
        // Limpieza de valores
        if (["gt", "gte", "lt", "lte"].indexOf(operador) >= 0 && !isNaN(valor))
          valor = parseFloat(valor);
        if (valor == "null") valor = null;
        // Aplicar el filtro
        //console.log("ApiLocalStorage filtro", coleccion, parametro)
        resultados = resultados.filter((r) => {
          let mostrar = true;
          const valorEnCampo = r[campo];
          //console.log("ApiLocalStorage find filter", coleccion, campo, operador, (valor || 'sinvalor'), (valorEnCampo || 'sinvalorencampo')) ;
          switch (operador) {
            case "eq":
            case "equals":
              mostrar = valorEnCampo == valor;
              break;
            case "gt":
              mostrar = valorEnCampo > valor;
              break;
            case "gte":
              mostrar = valorEnCampo >= valor;
              break;
            case "lt":
              mostrar = valorEnCampo < valor;
              break;
            case "lte":
              mostrar = valorEnCampo <= valor;
              break;
            case "contains":
            case "cs":
              mostrar = valorEnCampo.indexOf(valor) >= 0;
              break;
            case "ne":
              mostrar = valorEnCampo != valor;
              break;
            default:
              console.warn("No se reconoce el operador", operador);
              mostrar = false;
              break;
          }
          return mostrar;
        });
      }
    }
    //console.log("find resultados despues de params", coleccion, resultados)
    // Configuracion de resultados
    if (opciones.sort) {
      let sort = opciones.sort;
      if (typeof sort == "string") sort = [sort];
      for (const ordenable of sort) {
        const campo = ordenable.split(",")[0];
        const sentido = ordenable.split(",")[1];
        resultados = resultados.sort((a, b) => {
          if (sentido == 1 || sentido == "1")
            return a[campo] > b[campo] ? 1 : -1;
          else return a[campo] < b[campo] ? 1 : -1;
        });
      }
    }
    if (opciones.skip && !isNaN(opciones.skip))
      resultados = resultados.splice(opciones.skip, resultados.length);
    //console.log("find resultados despues de skip", coleccion, resultados)
    if (opciones.limit && !isNaN(opciones.limit))
      resultados = resultados.slice(0, opciones.limit);
    if (opciones.subset && typeof opciones.subset == "object") {
      console.log("subset", coleccion, params, opciones.subset);
      const paramsRequeridos = ["coleccion", "query", "sustituir"];
      for (const id in resultados) {
        for (const sub of opciones.subset) {
          // Checar integridad del query del subset
          for (const param of paramsRequeridos)
            if (!(sub[param] && sub[param].length > 0))
              throw new Error("Faltan parámetros del subset: " + param);
          // Construir el query
          let subres;
          const sustituir = resultados[id][sub.sustituir];
          if (sub.query.startsWith("_id") || sub.unico == true) {
            if (sustituir)
              try {
                subres = await metodos.get(sub.coleccion, sustituir);
              } catch (e) {
                subres = {};
              }
            else {
              console.log(
                "subset get sustituir nulo?",
                coleccion,
                sub.coleccion,
                sustituir
              );
              subres = {};
            }
          } else {
            let query = sub.query;
            if (!query.endsWith(",")) query += ",";
            if (query.split(",").length <= 1) query += ",eq,";
            const filtrossubset = [query + sustituir];
            console.log("subset find", sub.coleccion, filtrossubset);
            try {
              subres = await metodos.find(sub.coleccion, filtrossubset);
            } catch (e) {
              subres = [];
            }
          }
          // Añadir al objeto del resultado
          console.log(
            "subset subres",
            coleccion,
            sub.alias || sub.coleccion,
            subres
          );
          resultados[id][sub.alias || sub.coleccion] = subres;
        }
      }
    }
    //console.log("find resultados despues de subset", coleccion, resultados)
    // Listo
    return Promise.resolve(resultados);
  },
  save: function (coleccion, obj) /**/ {
    coleccion = (coleccion || "").toLowerCase();
    obj = obj || {};
    const comprimirDatos = this.comprimirDatos == true;
    //console.log("savelocal", coleccion, obj)
    return new Promise((resolve, reject) => {
      if (typeof obj != "object")
        reject({
          error: "El recurso a guardar no es un objeto",
        });
      else if (coleccion == "error")
        resolve({
          msg: "No se sincronizarán errores en modo Offline",
        });
      else
        try {
          const id = obj && obj._id && obj._id != "" ? obj._id : this.uuid();
          const label = this.prefijo + "." + coleccion + "." + id;
          let resource;
          if (typeof obj == "object") {
            obj._id = id;
            obj.version = new Date().getTime();
            resource = JSON.stringify(obj);
            //console.log("save comprimirDatos", comprimirDatos)
            if (true == comprimirDatos) {
              resource = LZString144.compress(resource);
            }
          }
          localStorage.setItem(label, resource);
          resolve(obj);
        } catch (e) {
          console.error("EAPILOCALSTORAGESAVE", e);
          reject({
            error: "No se pudo guardar localmente",
            raw: e,
          });
        }
    });
  },
  saveMulti: function (coleccion, objs) /**/ {
    coleccion = (coleccion || "").toLowerCase();
    objs = objs || {};
    const comprimirDatos = this.comprimirDatos == true;
    console.log("savelocal", coleccion, objs);
    return new Promise((resolve, reject) => {
      if (coleccion == "error")
        resolve({
          msg: "No se sincronizarán errores en modo Offline",
        });
      else
        try {
          for (const obj of objs) {
            const id =
              obj && obj._id && obj._id != "" ? obj._id : metodos.uuid();
            const label = datos.prefijo + "." + coleccion + "." + id;
            let resource;
            if (typeof obj == "object") {
              obj._id = id;
              obj.version = new Date().getTime();
              resource = JSON.stringify(obj);
              console.log("save comprimirDatos", comprimirDatos);
              if (true == comprimirDatos) {
                resource = LZString144.compress(resource);
              }
            }
            localStorage.setItem(label, resource);
          }
          resolve(objs);
        } catch (e) {
          console.error("EAPILOCALSTORAGESAVE", e);
          reject({
            error: "No se pudo guardar localmente",
            raw: e,
          });
        }
    });
  },
  delete: function (coleccion, id) /**/ {
    return new Promise((resolve, reject) => {
      try {
        const label = this.prefijo + "." + coleccion + "." + id;
        localStorage.removeItem(label);
        resolve(true);
      } catch (e) {
        console.error("EAPILOCALSTORAGEDELETE", e);
        reject(true);
      }
    });
  },
  deleteMulti: async function (modelo, lista) /**/ {
    lista = lista || [];
    modelo = modelo || "";
    const label = this.prefijo + "." + modelo + ".";
    for (const localKey of Object.keys(localStorage))
      for (const id of lista)
        if (label + id == localKey) await metodos.delete(modelo, id);
    return Promise.resolve(true);
  },
  // geolocalizacion
  geolocate: function () /**/ {
    const y = metodos.fetchLocal("yo") || {};
    return new Promise(async (resolve, reject) => {
      if (
        "geolocation" in navigator &&
        y &&
        y._id &&
        datos.habilitarGeolocalizacion
      ) {
        navigator.geolocation.getCurrentPosition(
          async (pos) => {
            let c = {
              rol: y.rol || "invitado",
              userid: y._id || "",
              lat: pos.coords.latitude,
              long: pos.coords.longitude,
              date: new Date().getTime(),
              route: window.location.hash,
            };
            let u = null;
            u = await metodos.save("geo", c);
            console.log("Guardado geolocalizacion", u);
            resolve(u);
          },
          (e) => {
            reject({
              error: "No se pudo obtener la ubicación",
              raw: e,
            });
          }
        );
      } else {
        reject("No se pudo acceder al módulo de geolocalización");
      }
    });
  },
  /* A partir de aqui las funciones todas operan contra API */
  // Upload y download
  upload: function (handler, devolver) {
    handler = handler || "";
    devolver = devolver || "";
    return new Promise(async (resolve, reject) => {
      if (["", "binario", "base64"].indexOf(devolver) == -1)
        reject('Valor de devolución no valido ("", binario o base64)');
      else if (handler == "")
        reject("Debes especificar un query DOM en el primer argumento");
      const identificador = handler;
      let domHandler = document.querySelector(identificador);
      if (domHandler == null)
        domHandler = document.querySelector("#" + identificador);
      console.log(
        "Cargando archivo del identificador",
        identificador,
        domHandler
      );
      const h = domHandler.files[0];
      const fr = new FileReader();
      if (devolver == "base64") fr.readAsDataURL(h);
      //  fr.readAsBinaryString(h);
      else fr.readAsArrayBuffer(h);
      fr.onloadend = async () => {
        const binario = fr.result;
        console.log("Bin", handler, binario);
        if (devolver != "") resolve(binario);
        /*
          else
          try {
            let k = await Firebase.storageSave(h.name, binario)
            console.log("api upload rutaArchivo?", k)
            let rutaArchivo = await Firebase.storageGet(h.name, true)
            console.log("api upload rutaArchivo", rutaArchivo)
            resolve(rutaArchivo)
          } catch (e) {
            reject(e)
          }
        console.info("FileReader", h);
        //*/
      };
    });
  },
  // Rotator
  rotator: function (foto) {
    return new Promise((resolve, reject) => {
      if (foto.indexOf(datos.cdn) >= 0)
        foto = foto.replace(datos.cdn + "/", "");
      console.log("Pidiendo rotación de", foto);
      if (!foto || foto == "")
        reject({
          error: "No fue especificada la foto a girar",
        });
      axios
        .post(
          datos.servidor + "/rotator",
          {
            img: foto,
          },
          {
            headers: {
              "wst-appkey": "vueonic",
              token: metodos.token(),
            },
          }
        )
        .then((p) => {
          if (p && p.data && p.data.nombre) resolve(p.data.nombre);
          else
            reject({
              error: "No se pudo obtener la nueva imagen rotada",
              raw: p,
            });
        })
        .catch((e) => {
          reject({
            error: "No se pudo recuperar la imagen rotada",
            raw: e,
          });
        });
    });
  },
  // Thumbnailer. Para ApiLocalStorage, no se reduce el tamaño
  thumbnailer: function (handler) {
    console.log(
      "thumbnailer no está disponible. Subiendo imagen completa.",
      handler
    );
    return metodos.upload(handler);
  },
  // Archivos
  download: function (str = "") {
    const ruta =
      str.indexOf("http") >= 0 ? this.servidor + "/download/" + str : str;
    const token = this.token;
    return axios.get(ruta, {
      headers: {
        "wst-appkey": "vueonic",
        token: token(),
      },
    });
  },
  downloadBase64: function (ruta) {
    if (!datos.modoOffline) {
      return new Promise((resolve, reject) => {
        metodos.download(ruta).then(
          (obj) => {
            const header = "data:application/binary;base64,";
            resolve(header + obj.data.data);
          },
          (err) => {
            reject(err);
          }
        );
      });
    } else {
      return Promise.reject({
        error: "No se puede usar el CDN en modo Offline",
      });
    }
  },
  downloadForce: function (ruta) {
    if (!datos.modoOffline) {
      metodos.downloadBase64(ruta).then((blob) => {
        let a = document.createElement("a");
        a.href = blob;
        a.download = ruta;
        a.style.display = "none";
        a.style.visibility = "hidden";
        document.querySelector("body").appendChild(a);
        a.click();
      });
    } else {
      return Promise.reject({
        error: "No se puede usar el CDN en modo Offline",
      });
    }
  },
  // Email
  email: function (obj) {
    console.log("Enviando correo", obj);
    if (!this.modoOffline) {
      return new Promise(async (resolve, reject) => {
        if (
          !obj.emailOrigen ||
          !obj.nombreOrigen ||
          !obj.mensaje ||
          !obj.emailDestino
        )
          reject({
            error: "Faltan datos",
            raw: "emailOrigen,nombreOrigen,mensaje,emailDestino",
          });
        else
          try {
            const ruta = this.servidor + "/email";
            axios
              .post(ruta, obj, {
                headers: {
                  "wst-appkey": "vueonic",
                  token: metodos.token(),
                },
              })
              .then((r) => {
                resolve(r);
              })
              .catch((e) => {
                reject(e);
              });
          } catch (e) {
            console.error("ECORREO", e);
            reject({
              error: "No se pudo enviar el correo",
              raw: e,
            });
          }
      });
    } else {
      return Promise.reject({
        error: "No se pueden enviar emails en modo Offline",
      });
    }
  },
  // libreofficetopdf
  libreofficetopdf: async function (archivo) {
    archivo = archivo || "";
    const ruta = this.servidor + "/libreofficetopdf";
    if (archivo == "")
      return Promise.reject({
        error: "No fue especificado un archivo a convertir",
      });
    else
      return axios.post(ruta, {
        archivo,
      });
  },
  // Funciones de cache
  saveCache: function (modelo, obj) {
    if (modelo && obj) {
      console.log("Save cache", modelo, obj._id || "");
      if (datos.tiempoCache != 0 && modelo && obj._id && obj.fecha) {
        if (
          obj.fecha >= new Date().getTime() - datos.tiempoCache || // Está en rango de tiempo
          datos.WSTcolecciones.indexOf(modelo) != -1 // La app requiere que sea cacheable
        ) {
          if (!datos.cache[modelo]) datos.cache[modelo] = {};
          datos.cache[modelo][obj._id] = obj;
        }
      }
    }
  },
  deleteCache: function (modelo, id) {
    if (modelo && id && datos.cache[modelo] && datos.cache[modelo][id])
      delete datos.cache[modelo][id];
  },
  //
  proxied: function (obj) {
    const ruta = datos.servidor + "/proxied";
    const params = ["url", "options", "params"];
    // params.REQUEST_TYPE
    for (const param of params)
      if (!obj[param])
        return Promise.reject("Falta el parámetro axios:", param);
    if (!datos.modoOffline) {
      return axios.post(ruta, obj, {
        headers: {
          "wst-appkey": "hummingbird3",
          token: metodos.token(),
        },
      });
    } else {
      return Promise.reject({
        error: "No se puede usar el proxy de aplicaciones en modo Offline",
      });
    }
  },
  qrcode: function (str) {
    console.log("QR", str);
    const ruta = datos.servidor + "/qrcode";
    if (!this.modoOffline) {
      return axios.post(
        ruta,
        {
          cadena: str,
        },
        {
          headers: {
            "wst-appkey": "hummingbird3",
            token: metodos.token(),
          },
        }
      );
    } else {
      return Promise.reject({
        error: "No se puede usar el módulo QR en modo Offline",
      });
    }
  },
  getfile: function (modelo, id, campo, descargarComoBinario) {
    modelo = modelo || "";
    id = id || "";
    campo = campo || "data";
    const binario = descargarComoBinario == true ? "1" : "0";
    const ruta =
      datos.servidor + ["/getfile", modelo, id, campo, binario].join("/");
    console.log("getfile", ruta);
    if (!datos.modoOffline) {
      return axios.get(ruta, {
        headers: {
          "wst-appkey": "hummingbird3",
          token: metodos.token(),
        },
      });
    } else {
      return Promise.reject({
        error: "No se pudo recuperar el archivo",
      });
    }
  },
  //
  procesarUsuarioGoogle: async function (u) {
    console.log("gdrive procesarUsuarioGoogle?", JSON.stringify(u));
    try {
      let usuario = {
        empesa: "local",
        rol: "administrador",
      };
      for (const prop in u) {
        if (typeof u[prop] == "string") {
          console.log("detectado id", u[prop]);
          usuario.googleId = u[prop];
        } else {
          for (const prop2 in u[prop]) {
            //u[prop][prop2] = (u[prop][prop2] || '').toString()
            if (typeof u[prop][prop2] == "string") {
              console.log("prop?", prop, prop2, u[prop][prop2]);
              if (u[prop][prop2].indexOf("@") >= 1) {
                console.log("detectado email", u[prop][prop2]);
                usuario.email = u[prop][prop2];
              }
              if (u[prop][prop2].indexOf("googleusercontent") >= 1) {
                console.log("detectada imagen", u[prop][prop2]);
                usuario.imagen = u[prop][prop2];
              }
              if (u[prop][prop2].startsWith("eyJ")) {
                console.log("detectado token", u[prop][prop2]);
                usuario.jwt = u[prop][prop2];
              }
              if (u[prop][prop2].startsWith("ya29") || prop == "access_token") {
                console.log("detectado token", u[prop][prop2]);
                usuario.access_token = u[prop][prop2];
              }
              if (prop2.length == 2 && u[prop][prop2].split(" ").length >= 2) {
                console.log("detectado nombre", u[prop][prop2]);
                usuario.nombre = u[prop][prop2];
              }
            }
          }
        }
      }
      console.log("Usuario construido", usuario);
      usuario._id = sha1(usuario.email || "").substring(0, 24);
      usuario.password = sha1("password");
      usuario = await metodos.save("directorio", usuario);
      return Promise.resolve(usuario);
    } catch (e) {
      console.error(e);
      return Promise.reject(e);
    }
  },
};
export default {
  ...datos,
  ...metodos,
};
</script>
