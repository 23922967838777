<script>
let Permisos = {
  sujeto: {}, // Se inicializa desde Api.vue
  permiso: (str = "", usr = {}) => {
    if (!usr._id)
      usr = {
        permisos: {},
        permiso: {},
        rol: "usuario",
        ...Permisos.sujeto,
      };

    return (
      usr.rol == "administrador" ||
      usr.permisos[str] == true ||
      usr.permiso[str] == true
    );
  },
};
export default Permisos;
</script>
