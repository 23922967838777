<script>
import ApiWst from "./ApiWst";
import Permisos from "./Permisos";
const yo = ApiWst.fetchLocal("yo") || {};
import HelperFunctions from "./HelperFunctions";
import FcmFunctions from "./FcmFunctions";
HelperFunctions.sujeto = yo;
const exportable = {
  ...ApiWst,
  ...Permisos,
  ...HelperFunctions,
  ...FcmFunctions,
};
export default exportable;
</script>
