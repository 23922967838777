// src/fcmService.js

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const fcmService = {
  firebaseConfig: {},

  messaging: null,

  initializeFirebase: function (config = {}) {
    try {
      // Perform the mapping from google-services.json structure to Firebase config
      const firebaseConfig = {};
      firebaseConfig.apiKey = config.client[0].api_key[0].current_key;
      firebaseConfig.authDomain = `${config.project_info.project_id}.firebaseapp.com`;
      firebaseConfig.projectId = config.project_info.project_id;
      firebaseConfig.storageBucket = config.project_info.storage_bucket;
      firebaseConfig.messagingSenderId = config.project_info.project_number;
      firebaseConfig.appId = config.client[0].client_info.mobilesdk_app_id;
      // measurementId can be added if available or needed

      // Initialize Firebase with the mapped config
      initializeApp(firebaseConfig);
      this.firebaseConfig = firebaseConfig;
      this.messaging = getMessaging();
      this.setupMessageListener();
      return { ...this.messaging };
    } catch (e) {
      return Promise.reject(e);
    }
  },

  async requestPermission() {
    try {
      // Initialize Firebase if not already initialized
      if (!this.firebaseConfig.appId) this.initializeFirebase();

      // Request permission to send notifications
      await Notification.requestPermission();

      // If permission is granted, get the FCM token
      if (Notification.permission === "granted") {
        const currentToken = await getToken(this.messaging);

        if (currentToken) {
          console.log("FCM Token:", currentToken);
          // You can send this token to your server for further processing
          // Example: this.sendTokenToServer(currentToken);
          return currentToken;
        } else {
          console.log("No registration token available.");
          return null;
        }
      } else {
        console.log("Notification permission denied.");
        return null;
      }
    } catch (error) {
      console.error("Error getting FCM token:", error);
      return null;
    }
  },

  async sendNotification(
    msgObj = {
      tokens: [],
      title: "",
      body: "",
      image: null,
    }
  ) {
    try {
      msgObj.tokens = msgObj.tokens.filter((u) => u?.length > 0);
      const response = await fetch("https://app.encorp.mx:8083/encorp/fcm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "wst-appkey": "encorpnotif",
        },
        body: JSON.stringify(msgObj),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || "Failed to send notification");
      }

      console.log("Notification sent successfully:", result);
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  },

  async sendDataOnlyMessage(tokens, data) {
    try {
      const response = await fetch("https://app.encorp.mx:8083/encorp/fcm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tokens: tokens,
          data: data,
        }),
      });

      const result = await response.json();
      if (!response.ok) {
        throw new Error(result.error || "Failed to send data-only message");
      }

      console.log("Data-only message sent successfully:", result);
    } catch (error) {
      console.error("Error sending data-only message:", error);
    }
  },

  retrieveToken() {
    return this.requestPermission();
  },

  setupMessageListener() {
    onMessage(this.messaging, (payload) => {
      console.log("Message received:", payload);
      // Handle the message (e.g., show notification or update UI)
      if (typeof window.alert != "undefined" && payload.notification?.body)
        window.alert(payload.notification?.body, payload.notification?.title);
    });
  },
};

export default fcmService;
